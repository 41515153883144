body {
  margin: 0;
  padding: 0;
  font-family: helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  height: 100%;
  color: white;
  background-color: #282c34;
}

.body-container {
  padding: 7em 0;
}

.ui.inverted.menu {
  background-color: #f9fbfb;
  padding-left: 1.3em;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
  height: 58.45px;
}

.ui.inverted.menu .item {
  color: #111;
  font-size: 16px;
}

.ui.inverted.menu .link.item.item:hover, .ui.inverted.menu .active.item {
    color: #2185d0 !important;
    font-weight: bold;
    background-color: rgba(51,51,51,0.04);
}

.ui.vertical.menu .active.item {
    color: #2185d0 !important;
    font-weight: bold;
    background-color: rgba(51,51,51,0.04);
}

.ui.inverted.menu .link.item.icon-image-link:hover, .ui.inverted.menu .active.item {
    background: transparent;
}

.ui.button.contact-button {
  color: #111;
  background-color: #f9fbfb;
  border: 1px solid #111;
}

.active.item .ui.button.contact-button, .ui.button.contact-button:hover, .ui.button.contact-button:focus {
  color: #2185d0 !important;
  border-color: #2185d0;
}

.ui.inverted.menu .item:before {
  background: none;
}

.home-link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.link {
  height: 100%;
}

.dropdown-link {
  color: rgba(0,0,0,.87)!important;
}

.dropdown-link:hover {
  color: rgba(0,0,0,.95) !important;
  background: rgba(0,0,0,.05)!important;
}

.ui.menu:not(.vertical) .item>a>.sign-up-button {
  margin-left: 0.25em;
  margin-right: 0;
}

.ui.menu:not(.vertical) .item>a>.button:focus {
  box-shadow: 0 0 0 2px #fff inset!important;
  background: transparent none;
  color: #fff;
}

.ui.mini.image.logo {
  width: 120px;
}

.logo-thumb {
  background: url(/static/media/Logo.99345e5f.jpg) no-repeat 0 0;
  display: none;
}

.menu-mobile {
  display: none;
}

@media (max-width: 767px) {
  .menu-desktop {
    display: none;
  }

  body .ui.inverted::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,0.15) !important;
  }

  .menu-mobile {
    display: block;
    background-color: #f9fbfb;
  }

  .ui.labeled.icon.menu .item>.icon:not(.dropdown) {
      height: 0.5em;
      display: block;
      font-size: 1.21428571em!important;
      margin: 0 auto 1rem!important;
  }

  .menu-bar {
    height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .ui.segment {
    border: none;
    border-radius: 0;
    margin-top: 0;
    padding: 0;
  }

  .ui.menu .item {
    padding: 1em;
  }

  .sidebar.icon {
    margin-left: 15px;
    font-size: 1.5em;
    color: #111;
  }

  .home-link {
    color: #111 !important;
    font-size: 11px;
    font-weight: 700;
  }

  .logo {
    margin-right: 5px;
  }

  .fill {
    height: 1px;
    width: calc(1.43em + 15px);
  }
}

@media only screen and (min-width: 768px) {
  .ui.menu:not(.secondary):not(.text):not(.tabular):not(.borderless)>.container>.item:not(.right):not(.borderless):first-child {
    border-left: 0;
  }
}

@font-face {
  font-family: 'Audiowide';
  src: url(/static/media/Audiowide-Regular.1035cee0.ttf);
}

.ui.header.white {
  color: white;
  text-align: center;
  margin: 0;
  font-size: 40px;
  font-family: 'Audiowide', Fallback, helvetica;
  text-transform: uppercase;
}

.ui.text.container.body-container {
    max-width: 100% !important;
    width: 100%;
    margin: 0;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    background: linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5) ), url(/static/media/car-wallpaper.47c924cc.jpg) no-repeat 0 0;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.body-container h3 {
  font-size: 14px;
  margin: 0;
  text-align: center;
  font-style: italic;
}

.body-container p.mission {
  font-size: 24px;
  max-width: 630px;
  margin: 0;
}

.home-text-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.body-container p.quote {
  font-size: 32px;
  font-style: italic;
  margin: 0;
}

.body-container p.detail {
  font-size: 20px;
  margin-top: 15px;
}

.header-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.quick-links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 80%;
}

.quick-link {
  width: 20%;
  font-size: 24px;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.quick-link:hover {
  color: #2185d0;
}

.home-image-mobile {
  display: none;
}

@media (max-width: 630px) {
  .ui.text.container.body-container {
    background: linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5) ), url(/static/media/car-wallpaper-mobile.161d91c7.jpg) no-repeat 0 0;
    background-position: 0 -100px;
  }
}

@media (max-width: 1200px) and (orientation: portrait) {
  .ui.text.container.body-container {
    background: linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5) ), url(/static/media/car-wallpaper-mobile.161d91c7.jpg) no-repeat -10px 0;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .quick-links {
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    height: 400px;
  }

  .quick-link {
    width: 100%;
    margin: 5px;
    -webkit-flex: 1 0 34%;
            flex: 1 0 34%;
  }

  .quick-link i.big.icon {
    font-size: 3.5em;
  }

  .home-image-mobile {
    display: block;
    background: url(/static/media/LogoWhite.7450e32c.svg) no-repeat 0 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 255px;
    width: 100%;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .quick-links {
    width: 100%;
  }

  .home-container .ui.header.white {
      display: block !important;
  }
}

@media (max-width: 767px) {
    .ui.text.container.body-container {
      padding: 30px;
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
      height: calc(100vh - 50px) !important;
      margin: 0 !important;

    }

    .home-image-mobile {
      height: 90px;
    }

    .home-container .ui.header.white {
        display: none;
    }

    .body-container h3 {
      font-size: 12px;
      margin: 15px 0 0;
    }

    .body-container div span {
      font-size: 28px;
      font-weight: bold;
      color: #32CD32;
      margin-right: 0;
    }

    .body-container p.mission {
      display: none;
    }

    .home-text-container {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
    }

    .body-container p.quote {
      font-size: 18px;
      font-style: italic;
      margin-top: 10px;
    }
    
    .body-container p.detail {
      font-size: 16px;
      margin-bottom: 10px;
      width: 85%;
      text-align: center;
    }
}

.about-view {
  padding-top: 58.45px;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.about-view .ui.text.container.body-container {
  padding: 2em 5%;
  height: 100%;
  -webkit-justify-content: normal;
          justify-content: normal;
  background: linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5) ), url(/static/media/car-wallpaper3.ae9ca8a5.jpg) no-repeat 0 0;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100% !important;
}

.body-container p.about-sub {
  font-size: 26px;
  font-family: 'Lato';
  margin: 0;
  font-weight: 300;
  font-style: normal;
}

.body-container p.about-detail {
  font-size: 16px;
  font-family: 'Lato';
  font-weight: 300;
  font-style: normal;
  margin: 0;
}

.about-container {
  display: -webkit-flex;
  display: flex;
  padding-top: 3em;
}

.about-container-col {
  height: 100%;
  width: 50%;
}

.about-container-col-image {
  height: 100%;
  width: 25%;
}

.about-image {
  background: url(/static/media/zach-work.1a12a721.jpg) no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 2px;
}

.arc-image {
  background: url(/static/media/arc.d2511d60.jpeg) no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 2px;
}

@media (max-width: 767px) {
  .about-view .ui.text.container.body-container {
    padding: 30px !important;
  }
}

@media (max-width: 767px) and (orientation: portrait) {
  .about-view {
    height: calc(100vh - 50px) !important;
  }

  .about-image {
    background-size: cover !important;
  }
  
  .arc-image {
    background-size: cover !important;
  }
}

@media (max-width: 1200px) {
  .about-view .ui.text.container.body-container {
    padding: 90px;
    overflow: auto;
    background-position: 40%;
  }

  .about-view {
    padding-top: 0;
  }

  .about-container {
    padding-top: 0;
    width: 100%;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .about-container-col {
    margin: 5px 0;
    -webkit-flex: 1 0 51%;
            flex: 1 0 51%;
    height: auto;
  }
  
  .about-container-col-image {
    margin: 5px 0;
    -webkit-flex: 1 0 51%;
            flex: 1 0 51%;
    height: auto;
  }

  .about-image {
    height: 225px;
    background-size: contain;
  }
  
  .arc-image {
    height: 225px;
    background-size: contain;
  }

  .body-container p.about-detail {
    margin: 10px;
    font-size: 14px;
  }
}

.gallery-view {
  padding-top: 58.45px;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.carousel .control-dots {
  padding-left: 0;
}

::-webkit-scrollbar {
    display: none;
}

.gallery-view .header-text {
  padding-top: 2em;
  margin-bottom: 10px;
}

.gallery-view .ui.text.container.body-container {
  padding: 0;
  height: 100%;
  -webkit-justify-content: normal;
          justify-content: normal;
  background: linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5) ), url(/static/media/car-wallpaper3.ae9ca8a5.jpg) no-repeat 0 0;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: scroll;
}

.body-container p.gallery-sub {
  font-size: 26px;
  font-family: 'Lato';
  margin: 0;
  font-weight: 300;
  font-style: normal;
}

.gallery-container {
  margin: 5px -.25rem 5px !important;
}

.gallery-carousel {
  width: 80%;
  margin-bottom: 15px;
}

.legend {
  opacity: 1 !important;
}

@media (max-width: 767px) {
  .gallery-view {
    padding-top: 0;
    height: calc(100vh - 50px);
  }
}

@media (max-width: 1200px) {
  .gallery-view .ui.text.container.body-container {
    padding: 30px;
    background-position: 40%;
  }

  .gallery-view .header-text {
    padding-top: 0;
  }

  .gallery-view .ui.medium.images .image, .gallery-view .ui.medium.images img {
    width: auto;
  }

  .gallery-carousel {
    width: 100%;
    margin-bottom:
  }
}

.faq-view {
  padding-top: 58.45px;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.faq-view .ui.text.container.body-container {
  background: #111;
  padding: 2em 5% 0;
  height: 100%;
  -webkit-justify-content: normal;
          justify-content: normal;
  background: linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5) ), url(/static/media/car-wallpaper2.f1fa5c20.jpg) no-repeat 0 0;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.body-container p.faq-sub {
  font-size: 26px;
  font-family: 'Lato';
  margin: 0;
  font-weight: 300;
  font-style: normal;
}

.body-container p.faq-question {
  font-size: 26px;
  font-family: 'Lato';
  margin-bottom: 0;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  margin-bottom: 5px;
}

.faq-link {
  color: #2185d0 !important;
}

.faq-link:hover {
    color: #1e70bf !important;
}

.body-container p.faq-answer {
  font-size: 16px;
  font-family: 'Lato';
  width: 75%;
  line-height: normal;
}

@media (max-width: 1200px) {
  .faq-view .ui.text.container.body-container {
    padding: 30px;
    overflow: auto;
    background-position: 50%;
  }
}

@media (max-width: 767px) {
  .faq-view {
    padding-top: 0;
    height: calc(100vh - 50px);
  }

  .faq-view .ui.text.container.body-container {
    padding: 30px;
    overflow: auto;
    background-position: 50%;
  }

  .body-container p.faq-sub {
    -webkit-align-self: flex-start;
            align-self: flex-start;
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 10px;
  }

  .body-container p.faq-answer {
    width: 100%;
  }
}

.contact-view {
  padding-top: 58.45px;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.contact-view .ui.text.container.body-container {
  padding: 2em 5%;
  height: 100%;
  -webkit-justify-content: normal;
          justify-content: normal;
  background: linear-gradient( rgba(0,0,0,.65), rgba(0,0,0,.65) ), url(/static/media/car-wallpaper4.5773afae.jpg) no-repeat 0 0;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100% !important;
}

.body-container p.contact-sub {
  font-size: 26px;
  font-family: 'Lato';
  margin: 0;
  font-weight: 300;
  font-style: normal;
}

.contact-container {
  display: -webkit-flex;
  display: flex;
  padding-top: 2em;
  width: 100%;
}

.contact-container-col {
  height: 100%;
  width: 50%;
}

.contact-image {
  background: url(/static/media/LogoWhite.7450e32c.svg) no-repeat 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.contact-container-col .item {
  font-size: 24px;
  margin-left: 10%;
  padding: 10px 0;
}

.contact-container-col .item .content a {
  color: #2185d0 !important;
}

.contact-container-col .item:hover {
  color: #1e70bf !important;
}

.contact-container-col .item i {
  font-size: 1.5em;
}

.contact-image-mobile {
  display: none;
}

@media (max-width: 767px) and (orientation: portrait) {
  .contact-container {
    padding-top: 0;
    width: 100%;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .contact-container-col {
    margin: 5px 0;
    -webkit-flex: 1 0 51%;
            flex: 1 0 51%;
    height: auto;
  }

  .contact-image {
    display: none;
  }

  .contact-image-mobile {
    display: block;
    background: url(/static/media/LogoWhite.7450e32c.svg) no-repeat 0 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 90px;
    width: 100%;
  }

  .body-container p.contact-sub {
    -webkit-align-self: flex-start;
            align-self: flex-start;
    margin-top: 10px;
  }
}

@media (max-width: 850px) {
  .ui.header.white {
    font-size: 26px;
  }

  .header-text {
    width: 100%;
  }

  .contact-container-col .item {
    font-size: 18px;
    padding: 0;
    margin-left: 0;
  }

  .contact-view .ui.text.container.body-container {
    padding: 20px !important;
  }
}

@media (max-width: 767px) {
  .contact-view {
    padding-top: 0;
    height: calc(100vh - 50px);
  }

  .contact-view .ui.text.container.body-container {
    background-position: 40%;
  }
}

@media (max-width: 1200px) {
  .contact-view .ui.text.container.body-container {
    padding: 50px 0;
    overflow: auto;
    background-position: 40%;
  }
}

