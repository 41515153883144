.about-view {
  padding-top: 58.45px;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.about-view .ui.text.container.body-container {
  padding: 2em 5%;
  height: 100%;
  justify-content: normal;
  background: linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5) ), url('../../resources/car-wallpaper3.jpg') no-repeat 0 0;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100% !important;
}

.body-container p.about-sub {
  font-size: 26px;
  font-family: 'Lato';
  margin: 0;
  font-weight: 300;
  font-style: normal;
}

.body-container p.about-detail {
  font-size: 16px;
  font-family: 'Lato';
  font-weight: 300;
  font-style: normal;
  margin: 0;
}

.about-container {
  display: flex;
  padding-top: 3em;
}

.about-container-col {
  height: 100%;
  width: 50%;
}

.about-container-col-image {
  height: 100%;
  width: 25%;
}

.about-image {
  background: url('../../resources/zach-work.jpg') no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 2px;
}

.arc-image {
  background: url('../../resources/arc.jpeg') no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 2px;
}

@media (max-width: 767px) {
  .about-view .ui.text.container.body-container {
    padding: 30px !important;
  }
}

@media (max-width: 767px) and (orientation: portrait) {
  .about-view {
    height: calc(100vh - 50px) !important;
  }

  .about-image {
    background-size: cover !important;
  }
  
  .arc-image {
    background-size: cover !important;
  }
}

@media (max-width: 1200px) {
  .about-view .ui.text.container.body-container {
    padding: 90px;
    overflow: auto;
    background-position: 40%;
  }

  .about-view {
    padding-top: 0;
  }

  .about-container {
    padding-top: 0;
    width: 100%;
    flex-wrap: wrap;
  }

  .about-container-col {
    margin: 5px 0;
    flex: 1 0 51%;
    height: auto;
  }
  
  .about-container-col-image {
    margin: 5px 0;
    flex: 1 0 51%;
    height: auto;
  }

  .about-image {
    height: 225px;
    background-size: contain;
  }
  
  .arc-image {
    height: 225px;
    background-size: contain;
  }

  .body-container p.about-detail {
    margin: 10px;
    font-size: 14px;
  }
}
