.contact-view {
  padding-top: 58.45px;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.contact-view .ui.text.container.body-container {
  padding: 2em 5%;
  height: 100%;
  justify-content: normal;
  background: linear-gradient( rgba(0,0,0,.65), rgba(0,0,0,.65) ), url('../../resources/car-wallpaper4.jpg') no-repeat 0 0;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100% !important;
}

.body-container p.contact-sub {
  font-size: 26px;
  font-family: 'Lato';
  margin: 0;
  font-weight: 300;
  font-style: normal;
}

.contact-container {
  display: flex;
  padding-top: 2em;
  width: 100%;
}

.contact-container-col {
  height: 100%;
  width: 50%;
}

.contact-image {
  background: url('../../resources/LogoWhite.svg') no-repeat 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.contact-container-col .item {
  font-size: 24px;
  margin-left: 10%;
  padding: 10px 0;
}

.contact-container-col .item .content a {
  color: #2185d0 !important;
}

.contact-container-col .item:hover {
  color: #1e70bf !important;
}

.contact-container-col .item i {
  font-size: 1.5em;
}

.contact-image-mobile {
  display: none;
}

@media (max-width: 767px) and (orientation: portrait) {
  .contact-container {
    padding-top: 0;
    width: 100%;
    flex-wrap: wrap;
  }

  .contact-container-col {
    margin: 5px 0;
    flex: 1 0 51%;
    height: auto;
  }

  .contact-image {
    display: none;
  }

  .contact-image-mobile {
    display: block;
    background: url('../../resources/LogoWhite.svg') no-repeat 0 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 90px;
    width: 100%;
  }

  .body-container p.contact-sub {
    align-self: flex-start;
    margin-top: 10px;
  }
}

@media (max-width: 850px) {
  .ui.header.white {
    font-size: 26px;
  }

  .header-text {
    width: 100%;
  }

  .contact-container-col .item {
    font-size: 18px;
    padding: 0;
    margin-left: 0;
  }

  .contact-view .ui.text.container.body-container {
    padding: 20px !important;
  }
}

@media (max-width: 767px) {
  .contact-view {
    padding-top: 0;
    height: calc(100vh - 50px);
  }

  .contact-view .ui.text.container.body-container {
    background-position: 40%;
  }
}

@media (max-width: 1200px) {
  .contact-view .ui.text.container.body-container {
    padding: 50px 0;
    overflow: auto;
    background-position: 40%;
  }
}
