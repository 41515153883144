body {
  height: 100%;
  color: white;
  background-color: #282c34;
}

.body-container {
  padding: 7em 0;
}

.ui.inverted.menu {
  background-color: #f9fbfb;
  padding-left: 1.3em;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
  height: 58.45px;
}

.ui.inverted.menu .item {
  color: #111;
  font-size: 16px;
}

.ui.inverted.menu .link.item.item:hover, .ui.inverted.menu .active.item {
    color: #2185d0 !important;
    font-weight: bold;
    background-color: rgba(51,51,51,0.04);
}

.ui.vertical.menu .active.item {
    color: #2185d0 !important;
    font-weight: bold;
    background-color: rgba(51,51,51,0.04);
}

.ui.inverted.menu .link.item.icon-image-link:hover, .ui.inverted.menu .active.item {
    background: transparent;
}

.ui.button.contact-button {
  color: #111;
  background-color: #f9fbfb;
  border: 1px solid #111;
}

.active.item .ui.button.contact-button, .ui.button.contact-button:hover, .ui.button.contact-button:focus {
  color: #2185d0 !important;
  border-color: #2185d0;
}

.ui.inverted.menu .item:before {
  background: none;
}

.home-link {
  display: flex;
  align-items: center;
}

.link {
  height: 100%;
}

.dropdown-link {
  color: rgba(0,0,0,.87)!important;
}

.dropdown-link:hover {
  color: rgba(0,0,0,.95) !important;
  background: rgba(0,0,0,.05)!important;
}

.ui.menu:not(.vertical) .item>a>.sign-up-button {
  margin-left: 0.25em;
  margin-right: 0;
}

.ui.menu:not(.vertical) .item>a>.button:focus {
  box-shadow: 0 0 0 2px #fff inset!important;
  background: transparent none;
  color: #fff;
}

.ui.mini.image.logo {
  width: 120px;
}

.logo-thumb {
  background: url('./resources/Logo.jpg') no-repeat 0 0;
  display: none;
}

.menu-mobile {
  display: none;
}

@media (max-width: 767px) {
  .menu-desktop {
    display: none;
  }

  body .ui.inverted::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,0.15) !important;
  }

  .menu-mobile {
    display: block;
    background-color: #f9fbfb;
  }

  .ui.labeled.icon.menu .item>.icon:not(.dropdown) {
      height: 0.5em;
      display: block;
      font-size: 1.21428571em!important;
      margin: 0 auto 1rem!important;
  }

  .menu-bar {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ui.segment {
    border: none;
    border-radius: 0;
    margin-top: 0;
    padding: 0;
  }

  .ui.menu .item {
    padding: 1em;
  }

  .sidebar.icon {
    margin-left: 15px;
    font-size: 1.5em;
    color: #111;
  }

  .home-link {
    color: #111 !important;
    font-size: 11px;
    font-weight: 700;
  }

  .logo {
    margin-right: 5px;
  }

  .fill {
    height: 1px;
    width: calc(1.43em + 15px);
  }
}

@media only screen and (min-width: 768px) {
  .ui.menu:not(.secondary):not(.text):not(.tabular):not(.borderless)>.container>.item:not(.right):not(.borderless):first-child {
    border-left: 0;
  }
}
