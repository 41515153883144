@font-face {
  font-family: 'Audiowide';
  src: url('../../resources/Audiowide-Regular.ttf');
}

.ui.header.white {
  color: white;
  text-align: center;
  margin: 0;
  font-size: 40px;
  font-family: 'Audiowide', Fallback, helvetica;
  text-transform: uppercase;
}

.ui.text.container.body-container {
    max-width: 100% !important;
    width: 100%;
    margin: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5) ), url('../../resources/car-wallpaper.jpg') no-repeat 0 0;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: space-between;
}

.body-container h3 {
  font-size: 14px;
  margin: 0;
  text-align: center;
  font-style: italic;
}

.body-container p.mission {
  font-size: 24px;
  max-width: 630px;
  margin: 0;
}

.home-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body-container p.quote {
  font-size: 32px;
  font-style: italic;
  margin: 0;
}

.body-container p.detail {
  font-size: 20px;
  margin-top: 15px;
}

.header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.quick-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
}

.quick-link {
  width: 20%;
  font-size: 24px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.quick-link:hover {
  color: #2185d0;
}

.home-image-mobile {
  display: none;
}

@media (max-width: 630px) {
  .ui.text.container.body-container {
    background: linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5) ), url('../../resources/car-wallpaper-mobile.jpg') no-repeat 0 0;
    background-position: 0 -100px;
  }
}

@media (max-width: 1200px) and (orientation: portrait) {
  .ui.text.container.body-container {
    background: linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5) ), url('../../resources/car-wallpaper-mobile.jpg') no-repeat -10px 0;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .quick-links {
    justify-content: space-around;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    height: 400px;
  }

  .quick-link {
    width: 100%;
    margin: 5px;
    flex: 1 0 34%;
  }

  .quick-link i.big.icon {
    font-size: 3.5em;
  }

  .home-image-mobile {
    display: block;
    background: url('../../resources/LogoWhite.svg') no-repeat 0 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 255px;
    width: 100%;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .quick-links {
    width: 100%;
  }

  .home-container .ui.header.white {
      display: block !important;
  }
}

@media (max-width: 767px) {
    .ui.text.container.body-container {
      padding: 30px;
      justify-content: flex-start;
      height: calc(100vh - 50px) !important;
      margin: 0 !important;

    }

    .home-image-mobile {
      height: 90px;
    }

    .home-container .ui.header.white {
        display: none;
    }

    .body-container h3 {
      font-size: 12px;
      margin: 15px 0 0;
    }

    .body-container div span {
      font-size: 28px;
      font-weight: bold;
      color: #32CD32;
      margin-right: 0;
    }

    .body-container p.mission {
      display: none;
    }

    .home-text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .body-container p.quote {
      font-size: 18px;
      font-style: italic;
      margin-top: 10px;
    }
    
    .body-container p.detail {
      font-size: 16px;
      margin-bottom: 10px;
      width: 85%;
      text-align: center;
    }
}
