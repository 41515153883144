.gallery-view {
  padding-top: 58.45px;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.carousel .control-dots {
  padding-left: 0;
}

::-webkit-scrollbar {
    display: none;
}

.gallery-view .header-text {
  padding-top: 2em;
  margin-bottom: 10px;
}

.gallery-view .ui.text.container.body-container {
  padding: 0;
  height: 100%;
  justify-content: normal;
  background: linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5) ), url('../../resources/car-wallpaper3.jpg') no-repeat 0 0;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: scroll;
}

.body-container p.gallery-sub {
  font-size: 26px;
  font-family: 'Lato';
  margin: 0;
  font-weight: 300;
  font-style: normal;
}

.gallery-container {
  margin: 5px -.25rem 5px !important;
}

.gallery-carousel {
  width: 80%;
  margin-bottom: 15px;
}

.legend {
  opacity: 1 !important;
}

@media (max-width: 767px) {
  .gallery-view {
    padding-top: 0;
    height: calc(100vh - 50px);
  }
}

@media (max-width: 1200px) {
  .gallery-view .ui.text.container.body-container {
    padding: 30px;
    background-position: 40%;
  }

  .gallery-view .header-text {
    padding-top: 0;
  }

  .gallery-view .ui.medium.images .image, .gallery-view .ui.medium.images img {
    width: auto;
  }

  .gallery-carousel {
    width: 100%;
    margin-bottom:
  }
}
